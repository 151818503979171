<template>
  <div>
    <b-card-actions
      title="Projects"
      ref="mainCard"
      action-refresh
      @refresh="refreshCard"
    >
      <b-row class="justify-content-between">
        <b-col md="4" class="d-flex align-items-center gap-1 mt-2">
          <b-badge variant="secondary rounded-circle" class="centered">
            {{ totalProjects }}
          </b-badge>
          <span>Total Projects</span>
        </b-col>
        <b-col md="4" class="d-flex align-items-center gap-1 mt-2">
          <b-badge variant="danger rounded-circle" class="centered">
            {{ overdueProjects }}
          </b-badge>
          <span>Overdue Projects</span>
        </b-col>
        <b-col md="4" class="d-flex align-items-center gap-1 mt-2">
          <b-badge variant="warning rounded-circle" class="centered">
            {{ notStartedProjects }}
          </b-badge>
          <span>Not Started Projects</span>
        </b-col>
        <b-col md="4" class="d-flex align-items-center gap-1 mt-2">
          <b-badge variant="success rounded-circle" class="centered">
            {{ completedProjects }}
          </b-badge>
          <span>Finished Projects</span>
        </b-col>
        <b-col md="4" class="d-flex align-items-center gap-1 mt-2">
          <b-badge variant="primary rounded-circle" class="centered">
            {{ inProgressProjects }}
          </b-badge>
          <span>In Progress Projects</span>
        </b-col>
        <b-col md="4" class="d-flex align-items-center gap-1 mt-2">
          <b-badge variant="info rounded-circle" class="centered">
            {{ canceledProjects }}
          </b-badge>
          <span>Canceled Projects</span>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between align-items-center mb-1 mt-2">
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Sort</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-100"
          />
          <download-excel
            :data="exportItems"
            :fields="exportFields"
            :filename="'Employee'"
            :sheetname="'Employee'"
            class="cursor-pointer ml-1"
          >
            <b-button
              variant="outline-secondary"
              size="sm"
              class="d-flex mr-1 export-btn"
            >
              <feather-icon icon="FileIcon" size="14" class="text-success" />
              <label style="font-size: 14px; margin-left: 5px; cursor: pointer"
                >Export</label
              >
            </b-button>
          </download-excel>
        </div>
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Search</label>
          <b-form-input
            id="searchInput"
            v-model="filter"
            size="sm"
            type="search"
            placeholder="Search"
          />
          <div class="ml-1">
            <b-button
              variant="primary"
              size="sm"
              style="width: 120px"
              v-b-modal.add-project
              v-if="getRole() != 'director'"
            >
              New Project
            </b-button>
          </div>
        </div>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            @filtered="onFiltered"
            class="header-wrap"
          >
            <template #cell(name)="data">
              <span
                v-b-modal="`detail_${data.value.id}`"
                class="text-primary"
                @click="get_detail_project(data.value.id)"
              >
                {{ data.value.name }}
              </span>
            </template>

            <template #cell(profile_photo_url)="data">
              <b-avatar-group size="32px">
                <b-avatar
                  v-for="(item, index) in data.value"
                  :key="index"
                  v-b-tooltip.hover
                  class="pull-up"
                  :title="item.name"
                  :src="item.profile_photo_path"
                />
              </b-avatar-group>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(progress)="data">
              <b-progress
                :value="data.value"
                max="100"
                show-progress
                :variant="
                  data.value >= 80
                    ? `success`
                    : data.value >= 30
                    ? `warning`
                    : `danger`
                "
                class="progress-bar-success"
              />
            </template>

            <template #cell(actions)="data">
              <div
                class="text-center"
                v-if="data.value.created_by == getAuthId()"
              >
                <feather-icon
                  v-b-modal="`edit_${data.value.id}`"
                  icon="EditIcon"
                  size="20"
                  class="text-primary"
                ></feather-icon>
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Showing
              {{ perPage > totalRows ? totalRows : perPage }} of
              {{ totalRows }} entries
            </span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </div>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-modal
      id="add-project"
      ref="add-project"
      title="Add Project"
      size="lg"
      hide-footer
    >
      <form @submit.prevent="submit_form">
        <b-row>
          <b-col md="6">
            <b-form-group label="Project Name">
              <b-form-input
                v-model="form.name"
                placeholder="Enter Project Name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Project Members">
              <v-select
                v-model="form.project_member"
                :options="users"
                label="name"
                :reduce="(user) => user.id"
                multiple
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Start Date">
              <flat-pickr
                v-model="form.start_date"
                :config="{
                  dateFormat: 'Y-m-d',
                  altInput: true,
                }"
                placeholder="Enter Start Date"
                class="form-control"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="6" id="end_date">
            <b-form-group label="End Date">
              <flat-pickr
                v-model="form.end_date"
                :config="{
                  dateFormat: 'Y-m-d',
                  altInput: true,
                }"
                placeholder="Enter Start Date"
                class="form-control"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mb-1">
            <b-form-checkbox
              v-model="selected"
              class="custom-control-primary"
              @click="without_deadline"
            >
              Without Deadline
            </b-form-checkbox>
          </b-col>
          <b-col md="6">
            <b-form-group label="Status">
              <v-select
                v-model="form.status"
                :options="project_status"
                :reduce="(status) => status"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Priority">
              <v-select
                v-model="form.priority"
                :options="project_priority"
                :reduce="(priority) => priority"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Description">
              <quill-editor v-model="description"></quill-editor>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right mt-2">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
      v-for="(item, index) in items"
      :key="index"
      :id="`edit_${item.id}`"
      :title="`Edit Project - ${item.name.name}`"
      size="lg"
      hide-footer
    >
      <form @submit.prevent="update_form(item)">
        <b-row>
          <b-col md="6">
            <b-form-group label="Project Name">
              <b-form-input
                v-model="item.name.name"
                placeholder="Enter Project Name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Project Members">
              <v-select
                v-model="item.assigned_to"
                :options="users"
                label="name"
                :reduce="(user) => user.id"
                multiple
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Start Date">
              <flat-pickr
                v-model="item.start_date"
                :config="{
                  dateFormat: 'Y-m-d',
                  altInput: true,
                }"
                placeholder="Enter Start Date"
                class="form-control"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="6" id="end_date">
            <b-form-group label="End Date">
              <flat-pickr
                v-model="item.due_date"
                :config="{
                  dateFormat: 'Y-m-d',
                  altInput: true,
                }"
                placeholder="Enter Start Date"
                class="form-control"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mb-1">
            <b-form-checkbox
              v-model="selected"
              class="custom-control-primary"
              @click="without_deadline"
            >
              Without Deadline
            </b-form-checkbox>
          </b-col>
          <b-col md="6">
            <b-form-group label="Status">
              <v-select
                v-model="item.status"
                :options="project_status"
                :reduce="(status) => status"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Priority">
              <v-select
                v-model="item.priority"
                :options="project_priority"
                :reduce="(priority) => priority"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Description">
              <quill-editor v-model="item.description"></quill-editor>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right mt-2">
          <b-button type="submit" variant="primary">Update</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
      v-for="(item, index) in items"
      :key="index"
      :id="`detail_${item.id}`"
      :title="`Detail Project - ${item.name.name}`"
      size="lg"
      hide-footer
    >
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="GridIcon" />
            <span>Overview</span>
          </template>
          <div v-html="item.description"></div>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="UsersIcon" />
            <span>Members</span>
          </template>
          <div
            class="d-flex justify-content-between align-items-center mb-1 mt-2"
          >
            <div class="d-flex align-items-center">
              <label for="" class="mr-1" style="font-size: 14px">Sort</label>
              <b-form-select
                id="perPageSelect"
                v-model="milestonesPerPage"
                size="sm"
                :options="milestonesPageOptions"
                class="w-100"
              />
            </div>
            <div class="d-flex align-items-center">
              <label for="" class="mr-1" style="font-size: 14px">Search</label>
              <b-form-input
                id="searchInput"
                v-model="milestonesFilter"
                size="sm"
                type="search"
                placeholder="Search"
              />
            </div>
          </div>
          <b-table
            striped
            hover
            responsive
            :per-page="membersPerPage"
            :current-page="currentMembersPage"
            :items="project_members"
            :filter="membersFilter"
            @filtered="onMembersFiltered"
            class="header-wrap"
          >
            <template #cell(status)="data">
              <div class="text-left">
                <span v-if="data.value == 0" class="badge badge-light-primary">
                  New
                </span>
                <span v-if="data.value == 1" class="badge badge-light-success">
                  Completed
                </span>
                <span v-if="data.value == 2" class="badge badge-light-warning">
                  In Progress
                </span>
                <span v-if="data.value == 3" class="badge badge-light-danger">
                  Overdue
                </span>
              </div>
            </template>

            <template #cell(action)="data">
              <div class="text-left">
                <b-button variant="outline-danger" size="sm">
                  <feather-icon
                    icon="TrashIcon"
                    @click="deleteMilestone(data.value)"
                    size="14"
                    class="text-danger"
                    style="margin-right: 5px"
                  ></feather-icon>
                  Remove
                </b-button>
              </div>
            </template>
          </b-table>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Showing
              {{
                membersPerPage > totalMembersRows
                  ? totalMembersRows
                  : membersPerPage
              }}
              of {{ totalMembersRows }} entries
            </span>
            <b-pagination
              v-model="currentMembersPage"
              :total-rows="totalMembersRows"
              :per-page="membersPerPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </div>
        </b-tab>
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="FlagIcon" />
            <span>Milestones</span>
          </template>
          <div
            class="d-flex justify-content-between align-items-center mb-1 mt-2"
          >
            <div class="d-flex align-items-center">
              <b-button
                to="/project/milestone"
                variant="primary mr-1"
                size="sm"
              >
                New
              </b-button>
              <label for="" class="mr-1" style="font-size: 14px">Sort</label>
              <b-form-select
                id="perPageSelect"
                v-model="milestonesPerPage"
                size="sm"
                :options="milestonesPageOptions"
                class="w-100"
              />
            </div>
            <div class="d-flex align-items-center">
              <label for="" class="mr-1" style="font-size: 14px">Search</label>
              <b-form-input
                id="searchInput"
                v-model="milestonesFilter"
                size="sm"
                type="search"
                placeholder="Search"
              />
            </div>
          </div>

          <b-table
            striped
            hover
            responsive
            :per-page="milestonesPerPage"
            :current-page="currentMilestonesPage"
            :items="project_milestones"
            :filter="milestonesFilter"
            @filtered="onMilestonesFiltered"
            class="header-wrap"
          >
            <template #cell(status)="data">
              <div class="text-left">
                <span v-if="data.value == 0" class="badge badge-light-primary">
                  New
                </span>
                <span v-if="data.value == 1" class="badge badge-light-success">
                  Completed
                </span>
                <span v-if="data.value == 2" class="badge badge-light-warning">
                  In Progress
                </span>
                <span v-if="data.value == 3" class="badge badge-light-danger">
                  Overdue
                </span>
              </div>
            </template>

            <template #cell(action)="data">
              <div class="text-left">
                <b-button variant="outline-danger" size="sm">
                  <feather-icon
                    icon="TrashIcon"
                    @click="deleteMilestone(data.value)"
                    size="14"
                    class="text-danger"
                    style="margin-right: 5px"
                  ></feather-icon>
                  Remove
                </b-button>
              </div>
            </template>
          </b-table>

          <div class="d-flex justify-content-between align-items-center">
            <span>
              Showing
              {{
                milestonesPerPage > totalMilestonesRows
                  ? totalMilestonesRows
                  : milestonesPerPage
              }}
              of {{ totalMilestonesRows }} entries
            </span>
            <b-pagination
              v-model="currentMilestonesPage"
              :total-rows="totalMilestonesRows"
              :per-page="milestonesPerPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </div>
        </b-tab> -->
        <b-tab>
          <template #title>
            <feather-icon icon="CheckSquareIcon" />
            <span>Tasks</span>
          </template>

          <div
            class="d-flex justify-content-between align-items-center mb-1 mt-2"
          >
            <div class="d-flex align-items-center">
              <b-button
                to="/project"
                variant="primary mr-1"
                size="sm"
                v-if="getRole() != 'director'"
              >
                New
              </b-button>
              <label for="" class="mr-1" style="font-size: 14px">Sort</label>
              <b-form-select
                id="perPageSelect"
                v-model="milestonesPerPage"
                size="sm"
                :options="milestonesPageOptions"
                class="w-100"
              />
            </div>
            <div class="d-flex align-items-center">
              <label for="" class="mr-1" style="font-size: 14px">Search</label>
              <b-form-input
                id="searchInput"
                v-model="tasksFilter"
                size="sm"
                type="search"
                placeholder="Search"
              />
            </div>
          </div>

          <b-table
            striped
            hover
            responsive
            :per-page="tasksPerPage"
            :current-page="currentTasksPage"
            :items="project_tasks"
            :filter="tasksFilter"
            @filtered="onTasksFiltered"
            class="header-wrap"
          >
            <template #cell(status)="data">
              <div class="text-left">
                <span v-if="data.value == 0" class="badge badge-light-primary">
                  New
                </span>
                <span v-if="data.value == 1" class="badge badge-light-success">
                  Completed
                </span>
                <span v-if="data.value == 2" class="badge badge-light-warning">
                  In Progress
                </span>
                <span v-if="data.value == 3" class="badge badge-light-danger">
                  Overdue
                </span>
              </div>
            </template>

            <template #cell(action)="data">
              <div class="text-left">
                <b-button variant="outline-danger" size="sm">
                  <feather-icon
                    icon="TrashIcon"
                    @click="deleteMilestone(data.value)"
                    size="14"
                    class="text-danger"
                    style="margin-right: 5px"
                  ></feather-icon>
                  Remove
                </b-button>
              </div>
            </template>
          </b-table>

          <div class="d-flex justify-content-between align-items-center">
            <span>
              Showing
              {{
                tasksPerPage > totalTasksRows ? totalTasksRows : tasksPerPage
              }}
              of {{ totalTasksRows }} entries
            </span>
            <b-pagination
              v-model="currentTasksPage"
              :total-rows="totalTasksRows"
              :per-page="tasksPerPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FileIcon" />
            <span>Files</span>
          </template>

          <b-form-group label="Files">
            <!-- <b-form-file
              id="files"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="onFileInput($event, item.id)"
              multiple
            /> -->
            <vue-dropzone :options="dropzoneOptions" id="dropzone" />
          </b-form-group>

          <hr />

          <b-row>
            <b-col
              md="2"
              v-for="file in project_files"
              :key="file.id"
              class="mb-2"
              no-body
            >
              <b-card>
                <b-card-body>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <a :href="file.file_name">
                        <feather-icon
                          icon="FileIcon"
                          size="14"
                          class="text-success"
                        />
                      </a>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="MessageCircleIcon" />
            <span>Discussions</span>
          </template>

          <div
            class="d-flex justify-content-between align-items-center mb-1 mt-2"
          >
            <div class="d-flex align-items-center">
              <label for="" class="mr-1" style="font-size: 14px">Sort</label>
              <b-form-select
                id="perPageSelect"
                v-model="discussionsPerPage"
                size="sm"
                :options="discussionPageOptions"
                class="w-100"
              />
            </div>
            <div class="d-flex align-items-center">
              <label for="" class="mr-1" style="font-size: 14px">Search</label>
              <b-form-input
                id="searchInput"
                v-model="discussionsFilter"
                size="sm"
                type="search"
                placeholder="Search"
              />
            </div>
          </div>

          <div
            v-for="discussion in project_discussions"
            :key="discussion.id"
            class="d-flex align-items-center discuss"
          >
            <div>
              <b-avatar size="md" class="mr-1" :src="discussion.avatar" />
            </div>
            <div style="line-height: 1px">
              <h6>{{ discussion.title }}</h6>
              <h6>{{ discussion.created_by }}</h6>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span>
              Showing
              {{
                discussionsPerPage > totalDiscussionsRows
                  ? totalDiscussionsRows
                  : discussionsPerPage
              }}
              of {{ totalDiscussionsRows }} entries
            </span>
            <b-pagination
              v-model="currentDiscussionsPage"
              :total-rows="totalDiscussionsRows"
              :per-page="discussionsPerPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="BookIcon" />
            <span>Note</span>
          </template>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<style scoped>
.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
.centered {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

span {
  font-size: 12px;
}

.gap-1 {
  gap: 0.75rem;
}

.discuss {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding: 10px 0;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background: #f0efef;
}

.discuss:hover {
  background: #e0dfdf;
  cursor: pointer;
}
</style>
<script>
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { BButton, BTab, BTabs, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    quillEditor,
    vueDropzone: vue2Dropzone,
    ToastificationContent,
    BButton,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      projects: [],
      project_milestones: [],
      project_files: [],
      project_tasks: [],
      project_members: [],
      project_discussions: [],
      users: [],
      project: "",
      assignedTo: "",
      assignedBy: "",
      dateRange: "",
      status: "",
      statuses: [],
      user_logged: "",

      perPage: 5,
      milestonesPerPage: 5,
      tasksPerPage: 5,
      membersPerPage: 5,
      discussionsPerPage: 5,
      pageOptions: [3, 5, 10],
      milestonesPageOptions: [3, 5, 10],
      tasksPageOptions: [3, 5, 10],
      membersPageOptions: [3, 5, 10],
      discussionPageOptions: [3, 5, 10],
      totalRows: 1,
      totalMilestonesRows: 1,
      totalTasksRows: 1,
      totalMembersRows: 1,
      totalDiscussionsRows: 1,
      currentPage: 1,
      currentMilestonesPage: 1,
      currentTasksPage: 1,
      currentMembersPage: 1,
      currentDiscussionsPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      milestonesFilter: "",
      tasksFilter: "",
      membersFilter: "",
      discussionsFilter: "",
      fields: [
        {
          key: "no",
          label: "#",
        },
        { key: "name", label: "Project Name", sortable: true },
        {
          key: "profile_photo_url",
          label: "Project Members",
        },
        { key: "due_date", label: "Deadline", sortable: true },
        { key: "due_date", label: "Completion", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "progress", label: "Progress" },
        { key: "actions", label: "Actions" },
      ],
      items: [],

      fieldsMember: [
        { key: "name", label: "Name", sortable: true },
        { key: "id", label: "Action", sortable: true },
      ],

      status: [
        {
          New: "New",
          Progress: "Inprogress",
          Completed: "Completed",
          Canceled: "Canceled",
        },
        {
          New: "light-primary",
          Progress: "light-warning",
          Completed: "light-success",
          Canceled: "light-danger",
        },
      ],

      // export excel
      exportFields: {
        "Project Name": "p_name",
        "Project Description": "p_description",
        "Project Members": "p_members",
        "Start Date": "p_start_date",
        Deadline: "p_deadline",
        Progress: "p_progress",
        Status: "p_status",
      },
      exportItems: [],
      statuses: [
        {
          id: 1,
          color: "primary",
          value: "New",
        },
        {
          id: 2,
          color: "warning",
          value: "Progress",
        },
        {
          id: 3,
          color: "success",
          value: "Completed",
        },
        {
          id: 4,
          color: "danger",
          value: "Canceled",
        },
      ],

      dropzoneOptions: {
        url: "https://sw-api.hracademy.id/api/project-files/create",
        thumbnailWidth: 100,
        maxFilesize: 6,
        headers: {
          Authorization: "Bearer " + this.getCookie("sw_auth_token"),
        },
        uploadMultiple: true,
        paramName: "file_name",
        params: {
          user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          project_id: localStorage.getItem("project_detail_id"),
        },
      },

      editorOption: {
        modules: {
          "project-toolbar": "#project-toolbar",
        },
        content: "",
      },
      comment: ``,
      note: ``,
      form: {},
      project_status: ["New", "Progress", "Completed", "Canceled"],
      project_priority: ["Low", "Medium", "High"],
      description: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    totalProjects() {
      return this.items.length;
    },
    overdueProjects() {
      return this.items.filter((item) => item.due_date < new Date()).length;
    },
    completedProjects() {
      return this.items.filter((item) => item.status == "Completed").length;
    },
    canceledProjects() {
      return this.items.filter((item) => item.status == "Canceled").length;
    },
    notStartedProjects() {
      return this.items.filter((item) => item.status == "New").length;
    },
    inProgressProjects() {
      return this.items.filter((item) => item.status == "Progress").length;
    },
  },
  mounted() {
    this.user_logged = JSON.parse(localStorage.getItem("sw_auth_data")).id;

    // Set the initial number of items
    this.totalRows = this.items.length;

    // fetch data
    this.fetchData();
    this.get_members();
  },
  methods: {
    update_form(data) {
      let form = data;

      this.callApi({
        url: "/projects/update/" + this.hashid(form.id),
        method: "POST",
        data: {
          name: form.name.name,
          description: form.description,
          start_date: form.start_date,
          due_date: form.due_date,
          status: form.status,
          priority: form.priority,
          assigned_to: form.assigned_to.map((item) => item).join(","),
          created_by: form.created_by.id,
        },
        success: (response) => {
          this.$bvToast.toast("Project updated successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.fetchData();
        },
      });
    },

    onFileInput(e, id) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file_name", file);
      formData.append("project_id", id);
      formData.append("user_id", this.user_logged);

      this.callApi({
        url: "/project-files/create",
        method: "POST",
        data: formData,
        success: (response) => {
          this.$bvToast.toast("File uploaded successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.project_files.push(response.result);
        },
      });
    },

    refreshCard() {
      this.fetchData();
    },

    get_detail_project(id) {
      // this.project_milestones = [];
      this.project_tasks = [];
      this.project_members = [];
      this.project_files = [];
      this.project_discussions = [];

      this.callApi({
        method: "GET",
        url: "/projects/fetch",
        params: {
          id: this.hashid(id),
        },
        success: (response) => {
          response.result.map((item) => {
            item.assigned_to.map((member) => {
              this.project_members.push({
                name: member.name,
                action: member.id,
              });
            });
          });

          this.totalMembersRows = this.project_members.length;
        },
      });

      // this.callApi({
      //   method: "GET",
      //   url: "/milestones/fetch",
      //   params: {
      //     project_id: this.hashid(id),
      //   },
      //   success: (response) => {
      //     response.result.forEach((item, index) => {
      //       this.project_milestones.push({
      //         no: index + 1,
      //         "miletones title": item.name,
      //         status: item.status,
      //         action: item.id,
      //       });
      //     });

      //     this.totalMilestonesRows = this.project_milestones.length;
      //   },
      // });

      this.callApi({
        method: "GET",
        url: "/tasks/fetch",
        params: {
          project_id: this.hashid(id),
        },
        success: (response) => {
          response.result.forEach((item, index) => {
            this.project_tasks.push({
              no: index + 1,
              "task title": item.name,
              status: item.status,
              action: item.id,
            });
          });

          this.totalTasksRows = this.project_tasks.length;
        },
      });

      this.callApi({
        url: "/project-files/fetch",
        method: "GET",
        params: {
          project_id: this.hashid(id),
        },
        success: (response) => {
          this.project_files = response.result;
        },
      });

      this.callApi({
        url: "/project-discussions/fetch",
        method: "GET",
        params: {
          project_id: this.hashid(id),
        },
        success: (response) => {
          response.result.forEach((item, index) => {
            this.project_discussions.push({
              no: index + 1,
              title: item.title,
              body: item.body,
              created_by: item.user.name,
              avatar: item.user.profile_photo_path,
              action: item.id,
            });
          });

          this.totalDiscussionsRows = this.project_discussions.length;
        },
      });
    },

    get_members() {
      this.callApi({
        method: "GET",
        url: "/users/getAll",
        params: {
          limit: 100,
        },
        success: (response) => {
          response.result.data.forEach((item, index) => {
            this.users.push({
              id: item.id,
              name: item.name,
              profile_photo_url: item.profile_photo_url,
            });

            // except id 1
            this.users = this.users.filter((item) => item.id !== 1);
          });
        },
      });
    },

    without_deadline() {
      document.getElementById("end_date").style.display = "none";
    },

    fetchData() {
      this.$refs["mainCard"].showLoading = true;
      this.items = [];

      this.callApi({
        method: "GET",
        url: "/projects/fetch",
        success: (response) => {
          response.result.forEach((item, index) => {
            let progress = 0;

            if (item.tasks.length > 0) {
              let total = 0;
              let total_progress = 0;

              item.tasks.forEach((task) => {
                total += 1;
              });
              total_progress +=
                (item.tasks.filter((task) => task.status == "complete").length /
                  total) *
                100;

              progress = Math.round(total_progress);
            }

            this.items.push({
              no: (index += 1),
              id: item.id,
              profile_photo_url: item.assigned_to,
              name: { name: item.name, id: item.id },
              description: item.description,
              start_date: item.start_date,
              due_date: item.due_date,
              status: item.status,
              actions: { id: item.id, created_by: item.created_by.id },
              progress: progress,
              assigned_to: item.assigned_to.slice(0, 5),
              created_by: item.created_by,
            });

            // delete items hrcode 1101101
            this.items = this.items.filter((item) => {
              return item.hrcode !== "1101101";
            });
            this.exportItems.push({
              p_name: item.name,
              p_description: item.description,
              p_start_date: item.start_date,
              p_deadline: item.due_date,
              p_status: item.status,
              p_progress: `${progress}%`,
              p_members: item.assigned_to.map((item) => item.name).join(","),
            });
          });
          //
          this.totalRows = this.items.length;

          // export excel
          this.$refs["mainCard"].showLoading = false;
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Data is Empty",
              variant: "error",
            },
          });
          this.$refs["mainCard"].showLoading = false;
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onMilestonesFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalMilestonesRows = filteredItems.length;
      this.currentMilestonesPage = 1;
    },
    onMembersFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalMembersRows = filteredItems.length;
      this.currentMembersPage = 1;
    },
    onTasksFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalTasksRows = filteredItems.length;
      this.currentTasksPage = 1;
    },
    onDiscussionsFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalDiscussionsRows = filteredItems.length;
      this.currentDiscussionsPage = 1;
    },
    deleteEmployee(id) {
      this.callApi({
        method: "DELETE",
        url: "/users/delete/" + this.hashid(id),
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Delete successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to delete",
              variant: "error",
            },
          });
        },
      });
    },
    updateStatus(id, status) {
      this.callApi({
        method: "POST",
        url: "/tasks/update/" + this.hashid(id),
        data: {
          status: status,
        },
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Update successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to update",
              variant: "error",
            },
          });
        },
      });
    },

    submit_form() {
      let data = {
        name: this.form.name,
        description: this.description,
        start_date: this.form.start_date,
        due_date: this.form.end_date,
        status: this.form.status,
        assigned_to: this.form.project_member.join(","),
        priority: `${this.form.priority}`,
        created_by: JSON.parse(localStorage.getItem("sw_auth_data")).id,
      };

      this.callApi({
        method: "POST",
        url: "/projects/create",
        data: data,
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Create successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
          this.$bvModal.hide("add-project");
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to create",
              variant: "error",
            },
          });
        },
      });
    },
  },
};
</script>
<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.vue-dropzone > .dz-preview .dz-image {
  width: 0%;
  height: 0%;
  background: blue;
}
</style>
